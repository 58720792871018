import $ from "jquery";
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter.js';

let camera, scene, renderer, controls, animationId;
let artworkWidth, artworkHeight, artworkUrl, distanceToArtwork;
let spatialRunning = false;

$('.spatial').on( "click", function() {
    if (!spatialRunning) {
        spatialRunning = true;
        $('.spatialOverlay').removeClass('hidden');

        artworkWidth =  $( this ).data( "width" );
        artworkHeight = $( this ).data( "height" );
        artworkUrl = $( this ).data( "image" );

        if (artworkWidth > artworkHeight) {
            distanceToArtwork = artworkWidth + 60;
        } else {
            distanceToArtwork = artworkHeight + 60;
        }
    
        init();
        render();
    }
});

$('.spatialOverlayControls .close').on( "click", function() {
    if (spatialRunning) {
        spatialRunning = false;
        $('.spatialOverlay').addClass('hidden');
        
        renderer.dispose();
        cancelAnimationFrame(animationId);
    }
});

function init() {
    var canvReference = document.getElementById("spatialCanvas");

    renderer = new THREE.WebGLRenderer( { canvas: canvReference, antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.outputEncoding = THREE.sRGBEncoding;

    camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );
    camera.position.set( 0, 0, distanceToArtwork );
    

    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator( renderer );

    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xf0f0f0 );
    scene.environment = pmremGenerator.fromScene( environment ).texture;

    const texture = new THREE.TextureLoader().load( artworkUrl );

    const geometry = new THREE.PlaneGeometry( artworkWidth, artworkHeight, 10 );
    const material = new THREE.MeshBasicMaterial( {map: texture, side: THREE.DoubleSide} );
    const plane = new THREE.Mesh( geometry, material );
    plane.position.setZ(-5);
    plane.position.setY(5);
    scene.add( plane );

    const loader = new GLTFLoader();
    loader.load( '/assets/gltf/room_final.gltf', async function ( gltf ) {

        gltf.scene.position.setY(-20);
        scene.add( gltf.scene );

        

    //     USDZ

    //     const exporter = new USDZExporter();
    //     const arraybuffer = await exporter.parse( gltf.scene );
    //     const blob = new Blob( [ arraybuffer ], { type: 'application/octet-stream' } );

    //     const link = document.getElementById( 'link' );
    //     link.href = URL.createObjectURL( blob );

    } );

    // render();

    controls = new OrbitControls( camera, renderer.domElement );
    //controls.addEventListener( 'change', render ); // use if there is no animation loop
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 10;
    controls.maxDistance = distanceToArtwork + 30;
    controls.maxAzimuthAngle = Math.PI/3;
    controls.minAzimuthAngle = -Math.PI/3;
    controls.minPolarAngle = Math.PI/4.8;
    controls.maxPolarAngle = Math.PI/1.45;

    controls.enablePan = true;
    controls.panSpeed = 1.0;
    controls.screenSpacePanning = true; // if true, pan in screen-space
    controls.keyPanSpeed = 7.0;	// pixels moved per arrow key push

    const minPan = new THREE.Vector3( - artworkWidth/2, - artworkHeight/2, 0 );
    const maxPan = new THREE.Vector3( artworkWidth/2, artworkHeight/2, 0 );
    const _v = new THREE.Vector3();
    
    controls.addEventListener("change", function() {
        _v.copy(controls.target);
        controls.target.clamp(minPan, maxPan);
        _v.sub(controls.target);
        camera.position.sub(_v);
    })


    controls.update();

    window.addEventListener( 'resize', onWindowResize );

}



function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

    render();

}

//

function render() {
    animationId = requestAnimationFrame( render );
    controls.update();
    renderer.render( scene, camera );
    
}


console.log('Ar is running');